<template>
  <div id="main">
    <v-container class="center">
      <h2 class="text-center">
        404 Page Not Found
      </h2>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";

export default {
  name: "404 Not Found",
  components: { Footer },
  data() {},

  methods: {},
};
</script>

<style scoped>
@import "../styles/css/style.css";

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
