export const galleryList = [
  {
    title: 'Uji Kompetensi Bahasa Inggris SMKN 2 Denpasar',
    type: 'image',
    files: [
      {
        src: '5.jpg',
        size: 'horizontal',
      },
      {
        src: '6.jpg',
        size: 'vertical',
      },
      {
        src: '7.jpg',
        size: 'horizontal',
      },
      {
        src: '8.jpg',
        size: 'normal',
      },
      {
        src: '9.jpg',
        size: 'normal',
      },
      {
        src: '10.jpg',
        size: 'normal',
      },
      {
        src: '11.jpg',
        size: 'normal',
      },
      {
        src: '12.jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Try Out HeadWay Test & FIB Universitas Brawijaya',
    type: 'image',
    files: [
      {
        src: '17.jpg',
        size: 'horizontal',
      },
      {
        src: '18.jpg',
        size: 'normal',
      },
      {
        src: '19.jpg',
        size: 'vertical',
      },
      {
        src: '20.jpg',
        size: 'horizontal',
      },
      {
        src: '21.jpg',
        size: 'normal',
      },
    ]
  },

  {
    title: 'Try Out HeadWay Test & BLC Universitas Brawijaya',
    type: 'image',
    files: [
      {
        src: '13.jpg',
        size: 'horizontal',
      },
      {
        src: '14.jpg',
        size: 'big',
      },
      {
        src: '15.jpg',
        size: 'normal',
      },
      {
        src: '16.jpg',
        size: 'normal',
      },
    ]
  },

  {
    title: 'Try Out Mahasiswa FBS UWKS',
    type: 'image',
    files: [
      {
        src: '2019-1 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2019-1 (2).jpg',
        size: 'normal',
      },
      {
        src: '2019-1 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2019-1 (4).jpg',
        size: 'normal',
      },
      {
        src: '2019-1 (5).jpg',
        size: 'normal',
      },
      {
        src: '2019-1 (6).jpg',
        size: 'normal',
      },
    ]
  },

  {
    title: 'Try Out siswa SMA BSS',
    type: 'image',
    files: [
      {
        src: '2020-1 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-1 (2).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-1 (3).jpg',
        size: 'normal',
      },
      {
        src: '2020-1 (4).jpg',
        size: 'normal',
      },
      {
        src: '2020-1 (5).jpg',
        size: 'normal',
      },
      {
        src: '2020-1 (6).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Program TEP bagi siswa SMK PGRI 2 Denpasar',
    type: 'image',
    files: [
      {
        src: '2020-2 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-2 (2).jpg',
        size: 'normal',
      },
      {
        src: '2020-2 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2020-2 (4).jpg',
        size: 'normal',
      },
      {
        src: '2020-2 (5).jpg',
        size: 'normal',
      },
      {
        src: '2020-2 (6).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Program TEP bagi siswa SMK PGRI 2 Denpasar-2',
    type: 'image',
    files: [
      {
        src: '2020-3 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-3 (2).jpg',
        size: 'normal',
      },
      {
        src: '2020-3 (3).jpg',
        size: 'normal',
      },
      {
        src: '2020-3 (4).jpg',
        size: 'normal',
      },
      {
        src: '2020-3 (5).jpg',
        size: 'normal',
      },
      {
        src: '2020-3 (6).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Try Out Mahasiswa FIB Universitas Brawijaya',
    type: 'image',
    files: [
      {
        src: '2020-4 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-4 (2).jpg',
        size: 'vertical',
      },
      {
        src: '2020-4 (3).jpg',
        size: 'normal',
      },
      {
        src: '2020-4 (4).jpg',
        size: 'normal',
      },
      {
        src: '2020-4 (5).jpg',
        size: 'normal',
      },
      {
        src: '2020-4 (6).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Try Out mahasiswa FKIK Universitas Warmadewa',
    type: 'image',
    files: [
      {
        src: '2020-4 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-4 (2).jpg',
        size: 'normal',
      },
      {
        src: '2020-4 (3).jpg',
        size: 'normal',
      },
      {
        src: '2020-4 (4).jpg',
        size: 'horizontal',
      },
      {
        src: '2020-4 (5).jpg',
        size: 'normal',
      },
      {
        src: '2020-4 (6).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Program Kemitraan dengan P3B UMBJM',
    type: 'image',
    files: [
      {
        src: '2021-1 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2021-1 (2).jpg',
        size: 'normal',
      },
      {
        src: '2021-1 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2021-1 (4).jpg',
        size: 'normal',
      },
      {
        src: '2021-1 (5).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Program TEP di STIKES Suaka Insan',
    type: 'image',
    files: [
      {
        src: '2021-2 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2021-2 (2).jpg',
        size: 'vertical',
      },
      {
        src: '2021-2 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2021-2 (4).jpg',
        size: 'normal',
      },
      {
        src: '2021-2 (5).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Program Kemitraan dengan Warmadewa College',
    type: 'image',
    files: [
      {
        src: '2021-3 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2021-3 (2).jpg',
        size: 'normal',
      },
      {
        src: '2021-3 (3).jpg',
        size: 'normal',
      },
      {
        src: '2021-3 (4).jpg',
        size: 'horizontal',
      },
      {
        src: '2021-3 (5).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Partisipasi iTELL Converence 2022',
    type: 'image',
    files: [
      {
        src: '2022-1 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2022-1 (2).jpg',
        size: 'normal',
      },
      {
        src: '2022-1 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2022-1 (4).jpg',
        size: 'normal',
      },
      {
        src: '2022-1 (5).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Sosialiasi HeadWay Test di kota Solo',
    type: 'image',
    files: [
      {
        src: '2022-2 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2022-2 (2).jpg',
        size: 'normal',
      },
      {
        src: '2022-2 (3).jpg',
        size: 'normal',
      },
      {
        src: '2022-2 (4).jpg',
        size: 'normal',
      },
      {
        src: '2022-2 (5).jpg',
        size: 'normal',
      },
      {
        src: '2022-2 (6).jpg',
        size: 'normal',
      },
      {
        src: '2022-2 (7).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Program Magang Kerja mahasiswa FIB UB',
    type: 'image',
    files: [
      {
        src: '2022-3 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2022-3 (2).jpg',
        size: 'big',
      },
      {
        src: '2022-3 (3).jpg',
        size: 'normal',
      },
      {
        src: '2022-3 (4).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Try out mahasiswa FIB',
    type: 'image',
    files: [
      {
        src: '2022-4 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2022-4 (2).jpg',
        size: 'vertical',
      },
      {
        src: '2022-4 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2022-4 (4).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Program TKDA untuk Tendik UIN Malang',
    type: 'image',
    files: [
      {
        src: '2022-5 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2022-5 (2).jpg',
        size: 'normal',
      },
      {
        src: '2022-5 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2022-5 (4).jpg',
        size: 'normal',
      },
      {
        src: '2022-5 (5).jpg',
        size: 'normal',
      },
      {
        src: '2022-5 (6).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Kegiatan Kuliah Tamu BEM FBS UWKS',
    type: 'image',
    files: [
      {
        src: '2023-1 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2023-1 (2).jpg',
        size: 'normal',
      },
      {
        src: '2023-1 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2023-1 (4).jpg',
        size: 'normal',
      },
      {
        src: '2023-1 (5).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Kemitraan FEB UNR sebagai HTC Kota Denpasar',
    type: 'image',
    files: [
      {
        src: '2023-2 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2023-2 (2).jpg',
        size: 'horizontal',
      },
      {
        src: '2023-2 (3).jpg',
        size: 'horizontal',
      },
      {
        src: '2023-2 (4).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'Kemitraan CV Siraya Mas Edutama sebagai HTC Regional Bali',
    type: 'image',
    files: [
      {
        src: '2024-1 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2024-1 (2).jpg',
        size: 'big',
      },
      {
        src: '2024-1 (3).jpg',
        size: 'normal',
      },
      {
        src: '2024-1 (4).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'TKBI mahasiswa Diploma III  Poltekpar Bali',
    type: 'image',
    files: [
      {
        src: '2024-2 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2024-2 (2).jpg',
        size: 'vertical',
      },
      {
        src: '2024-2 (3).jpg',
        size: 'vertical',
      },
      {
        src: '2024-2 (4).jpg',
        size: 'horizontal',
      },
    ],
  },

  {
    title: 'TKBI mahasiswa Diploma IV  Poltekpar Bali',
    type: 'image',
    files: [
      {
        src: '2024-3 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2024-3 (2).jpg',
        size: 'normal',
      },
      {
        src: '2024-3 (3).jpg',
        size: 'normal',
      },
      {
        src: '2024-3 (4).jpg',
        size: 'normal',
      },
      {
        src: '2024-3 (5).jpg',
        size: 'normal',
      },
      {
        src: '2024-3 (6).jpg',
        size: 'normal',
      },
      {
        src: '2024-3 (7).jpg',
        size: 'normal',
      },
    ],
  },

  {
    title: 'Sponsorship Olimpiade Bhs Inggris provinsi Bali',
    type: 'image',
    files: [
      {
        src: '2024-4 (1).jpg',
        size: 'horizontal',
      },
      {
        src: '2024-4 (2).jpg',
        size: 'normal',
      },
      {
        src: '2024-4 (3).jpg',
        size: 'normal',
      },
      {
        src: '2024-4 (4).jpg',
        size: 'normal',
      },
      {
        src: '2024-4 (5).jpg',
        size: 'horizontal',
      },
      {
        src: '2024-4 (6).jpg',
        size: 'vertical',
      },
      {
        src: '2024-4 (7).jpg',
        size: 'horizontal',
      },
      {
        src: '2024-4 (8).jpg',
        size: 'normal',
      },
    ],
  },
];