<template>
  <div id="main">
    <div class="loading-screen center">
      <div class="loading-wrapper">
        <v-img
          contain
          :src="require('../assets/background/loader.gif')"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../styles/css/style.css";
.loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99999;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-wrapper {
  max-width: 100% !important;
}
</style>
