<template>
  <div id="main">
    <div id="content">
      <v-img
        class="mx-auto img-header"
        :src="require('../../assets/icon/logo_manpro.png')"
      ></v-img>

      <v-container class="container-xl text-center">
        <p class="header-title">MANPRO</p>
        <p class="sub-title">
          Manpro (Manajemen Proyek) will help you to manage administration,
          finance, payroll, files, budgets, transparency, prevent fraud, assist
          in managing and monitoring the ongoing process of implementing all
          projects, and keeping abreast of your project developments in the
          field in real-time.
        </p>
        <v-btn
          class="mt-12 mb-16 btn-primary btn-try"
          elevation="0"
          @click="(dialog = true), (bottomDialog = false), (dialog2 = false)"
          >Try free demo</v-btn
        >
      </v-container>

      <v-container fluid class="mt-0 px-0">
        <v-card class="card-preview" elevation="0">
          <v-card-actions class="justify-center">
            <v-item-group
              v-model="user"
              class="text-center d-inline-flex mb-9"
              mandatory
            >
              <v-item
                v-for="n in userData"
                :key="n"
                v-slot="{ active, toggle }"
                class="item-parent-spacer"
              >
                <v-btn
                  :input-value="active"
                  @click="toggle"
                  class="btn-windows"
                  v-text="n.name"
                  elevation="0"
                >
                </v-btn>
              </v-item>
            </v-item-group>
          </v-card-actions>
          <v-window v-model="user" class="window-border">
            <v-window-item
              v-for="parent in userData"
              :key="parent"
              align="center"
            >
              <v-card elevation="0" color="transparent">
                <v-card-actions class="justify-center hidden-sm-and-down">
                  <v-item-group
                    v-model="parent.onboard"
                    class="text-center d-inline-flex mb-9"
                    mandatory
                  >
                    <v-item
                      v-for="child in parent.onboardTitle"
                      :key="child"
                      v-slot="{ active, toggle }"
                      class="item-spacer"
                    >
                      <div :input-value="active" align="center" @click="toggle">
                        <p
                          class="text-medium text-menu-preview"
                          v-text="child"
                        ></p>
                        <hr />
                      </div>
                    </v-item>
                  </v-item-group>
                </v-card-actions>
                <v-card-actions class="justify-center hidden-md-and-up">
                  <v-slide-group
                    v-model="parent.onboard"
                    class="text-center d-inline-flex mb-9"
                    mandatory
                  >
                    <v-slide-item
                      v-for="child in parent.onboardTitle"
                      :key="child"
                      v-slot="{ active, toggle }"
                      class="item-spacer"
                    >
                      <div
                        :input-value="active"
                        style="cursor: pointer"
                        align="center"
                        @click="toggle"
                      >
                        <p
                          class="text-medium text-menu-preview"
                          v-text="child"
                        ></p>
                        <hr />
                      </div>
                    </v-slide-item>
                  </v-slide-group>
                </v-card-actions>
                <v-window v-model="parent.onboard" class="window-border">
                  <div class="arrows d-none d-lg-block">
                    <div class="arrow-prev">
                      <v-btn class="btn-arrow-window" @click="prev">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </div>
                    <div class="arrow-next">
                      <v-btn class="btn-arrow-window" @click="next">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <v-window-item v-for="pic in parent.dataWindows" :key="pic">
                    <viewer class="images" :options="viewOption" v-viewer>
                      <img
                        class="img-windows"
                        :src="require('../../assets/manpro/' + pic)"
                      />
                    </viewer>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card>
      </v-container>

      <v-container class="container-xl mt-16">
        <p class="second-title text-center">Features</p>
        <v-row class="mt-11 feature-item-spacer mx-auto d-none d-sm-flex">
          <v-col cols="12 px-16" style="display: contents !important">
            <v-card
              v-for="i in features"
              :key="i"
              elevation="0"
              class="card-features"
            >
              <v-img
                :width="i.width"
                height="32px"
                class="mx-md-0 mx-sm-0 mx-auto"
                :src="require('../../assets/icon/' + i.icon)"
              ></v-img>
              <p
                class="feature-title text-md-left text-sm-left text-center"
                v-text="i.title"
              ></p>
              <p
                class="feature-desc text-md-left text-sm-left text-center"
                v-text="i.desc"
              ></p>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-11 feature-item-spacer mx-auto hidden-sm-and-up">
          <v-col cols="12" style="display: contents !important" class="mx-auto">
            <v-card
              v-for="(i, index) in 4"
              :key="index"
              elevation="0"
              class="card-features"
            >
              <v-img
                :width="features[index].width"
                class="mx-md-0 mx-sm-0 mx-auto"
                :src="require('../../assets/icon/' + features[index].icon)"
              ></v-img>
              <p
                class="feature-title text-md-left text-sm-left text-center"
                v-text="features[index].title"
              ></p>
              <p
                class="feature-desc text-md-left text-sm-left text-center"
                v-text="features[index].desc"
              ></p>
            </v-card>
            <v-expand-transition>
              <div v-show="expand">
                <v-card
                  v-for="i in features.slice(4)"
                  :key="i"
                  elevation="0"
                  class="card-features"
                >
                  <v-img
                    width="32px"
                    height="32px"
                    class="mx-md-0 mx-sm-0 mx-auto"
                    :src="require('../../assets/icon/' + i.icon)"
                  ></v-img>
                  <p
                    class="feature-title text-md-left text-sm-left text-center"
                    v-text="i.title"
                  ></p>
                  <p
                    class="feature-desc text-md-left text-sm-left text-center"
                    v-text="i.desc"
                  ></p>
                </v-card>
              </div>
            </v-expand-transition>
            <p
              class="mx-auto text-medium color--primary"
              @click="expand = !expand"
            >
              <span v-text="expand ? 'Less features' : 'More features'"></span>
              <v-icon
                color="#0369B3"
                :class="{
                  'rotate-180deg': expand,
                }"
                >mdi-chevron-down</v-icon
              >
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-img
        width="100%"
        :src="require('../../assets/background/Rectangle 61.svg')"
        class="text-center img-try d-none d-sm-flex"
      >
        <p class="last-title">Do you want to try it first?</p>
        <v-btn class="btn-primary btn-try-2" @click="dialog = true"
          >Try free demo</v-btn
        >
      </v-img>

      <v-container fluid fill-height class="mt-16 hidden-sm-and-up">
        <v-row fill-height class="border-try py-5">
          <v-col cols="12">
            <p class="text-medium text-lg color--white my-auto text-center">
              Do you want to try it first?
            </p>
          </v-col>
          <v-col cols="12" align="center">
            <v-btn class="btn-primary btn-try-2 my-auto" @click="dialog = true">
              Try free demo
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- Dialog Pop Up -->
      <dialog-try
        v-if="dialog == true"
        :dialog="dialog"
        @dialog="dialog = false"
        :img="imgDialog"
        :page="pageName"
      />

      <dialog-notif
        :dialog2="dialog2"
        :bottomDialog="bottomDialog"
        @dialog2="dialog2 = false"
        @bottomDialog="bottomDialog = false"
        :page="pageName"
      />
      <!-- End Dialog Pop Up -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import DialogTry from "../../components/DialogTry.vue";
import DialogNotif from "../../components/DialogNotif.vue";

export default {
  name: "Donya",
  components: {
    Footer,
    DialogTry,
    DialogNotif,
  },
  data() {
    return {
      expand: false,
      user: 0,
      userData: [
        {
          name: "Admin",
          onboard: 0,
          length: 5,
          onboardTitle: [
            "Dashboard",
            "Cash Journal",
            "Budget Plan",
            "Cash Recap",
            "Cash Report",
          ],
          dataWindows: [
            "admin/dashboard.png",
            "admin/cash-journal.png",
            "admin/budget-plan.png",
            "admin/cash-recap.png",
            "admin/cash-report.png",
          ],
        },
        {
          name: "Project Leader",
          onboard: 0,
          length: 4,
          onboardTitle: [
            "Dashboard",
            "List of Absent",
            "Procurement Form",
            "Send Message",
          ],
          dataWindows: [
            "project/dashboard.png",
            "project/absen.png",
            "project/procurement.png",
            "project/message.png",
          ],
        },
        {
          name: "Director",
          onboard: 0,
          length: 6,
          onboardTitle: [
            "Dashboard",
            "Expense History",
            "Daily Report",
            "Absent History",
            "Procurement Approval",
            "Cash Recap",
          ],
          dataWindows: [
            "director/dashboard.png",
            "director/expense-history.png",
            "director/daily-report.png",
            "director/absen.png",
            "director/procurement.png",
            "director/cash-recap.png",
          ],
        },
      ],

      features: [
        {
          width: "56px",
          icon: "multi-platform.svg",
          title: "Multi Platform",
          desc: "Accessible via desktop and smartphone (Android & IOS)",
        },
        {
          width: "32px",
          icon: "grid.svg",
          title: "Integrated System",
          desc:
            "All your business activities such as sales, purchasing, stock, accounting, manufacturing, payroll, and assets will be mutually connected and well-integrated",
        },
        {
          width: "32px",
          icon: "infinite-sharp.svg",
          title: "Unlimited Transaction,Branches, and Users",
          desc:
            "There are no restrictions on transaction data, adding branches, and users",
        },
        {
          width: "32px",
          icon: "chatbubbles-outline.svg",
          title: "Collaborative",
          desc:
            "Actively collaborate with admin, project leader, and director with chat feature",
        },
        {
          width: "32px",
          icon: "newspaper-outline.svg",
          title: "Procedural",
          desc:
            "Complete procedures (requests and approvals) such as the procurement process, acceptance, use of funds, for transparency and maximizing the project budget",
        },
        {
          width: "32px",
          icon: "extension-puzzle-outline.svg",
          title: "Go Customization",
          desc: "Customize the system according to your needs",
        },
      ],

      dialog: false,
      dialog2: false,
      bottomDialog: false,
      imgDialog: "logo_manpro.png",
      pageName: "MANPRO",

      viewOption: {
        button: true,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: false,
      },
    };
  },

  mounted() {
    this.$root.$on("dialog2", () => {
      this.dialog2 = true;
    });

    this.$root.$on("bottomDialog", () => {
      this.bottomDialog = true;
    });
  },

  methods: {
    next() {
      this.userData[this.user].onboard =
        this.userData[this.user].onboard + 1 === this.userData[this.user].length
          ? 0
          : this.userData[this.user].onboard + 1;
    },
    prev() {
      this.userData[this.user].onboard =
        this.userData[this.user].onboard - 1 < 0
          ? this.userData[this.user].length - 1
          : this.userData[this.user].onboard - 1;
    },
  },
};
</script>

<style scoped>
@import "../../styles/css/style.css";
@import "../../styles/css/product.css";

.btn-windows {
  width: 142px;
  height: 43px;
  letter-spacing: 0px;
  background: transparent !important;
  border: 1px solid #d1d1d1 !important;
  color: #5a5a5a !important;
  border-radius: 32px !important;
  text-transform: none !important;
}

.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0 !important;
  background: white !important;
}

.theme--light.v-btn--active {
  background: white !important;
  border: 1px solid #004d93 !important;
  color: #004d93 !important;
}

.btn-windows:hover {
  background: white !important;
  border: 1px solid #004d93 !important;
  color: #004d93 !important;
}

.card-preview {
  width: 100%;
  min-height: 962px;
  padding: 33px 146px 110px 146px;
  border-radius: 64px !important;

  background: #f2faff;
}

.item-parent-spacer {
  margin: 0px 32px;
}

.img-windows {
  border-radius: 25px !important;
  box-shadow: (2px 2px 18px rgba(0, 0, 0, 0.1));
  max-width: 100% !important;
  height: 653px !important;
  object-fit: contain !important;
}

.img-header {
  height: 109px;
  width: 108px;
}

@media only screen and (max-width: 1080px) {
  .card-preview {
    min-height: auto;
    padding: 24px 16px;
    border-radius: 20px !important;
  }
}

@media only screen and (max-width: 960px) {
  .item-parent-spacer {
    margin: 0px 8px;
  }

  .card-preview {
    min-height: auto;
    padding: 24px 16px;
    border-radius: 20px !important;
  }

  .img-windows {
    border-radius: 25px !important;
    max-width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
  }
}

@media only screen and (max-width: 600px) {
  .item-parent-spacer {
    margin: 0px 4px;
  }

  .btn-windows {
    width: 100px;
    height: 43px;
    font-size: 12px;
  }

  .img-header {
    height: 82px;
    width: 81px;
  }
}

@media only screen and (max-width: 360px) {
  .btn-windows {
    margin: 4px;
    width: 90px;
    height: 31px !important;
    font-size: 10px;
  }
}
</style>
