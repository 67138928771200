<template>
  <div>
    <v-dialog v-model="dialog2" max-width="406" class="top">
      <v-card class="px-4" rounded="xl">
        <v-img
          @click="returnData()"
          height="16px"
          width="16px"
          :src="require('../assets/icon/x.svg')"
          class="float-right mt-3 pointer"
        ></v-img>
        <v-card-title class="pt-13 pr-0 pb-8 pl-6">
          <v-row class="p-0" fill-height justify="center" align="center">
            <v-col cols="12">
              <p class="dialog-title mb-2 text-center">
                Your request has been sent
              </p>
            </v-col>

            <v-col cols="12" align="center">
              <v-img
                width="110px"
                height="110px"
                :src="require('../assets/icon/success.svg')"
              ></v-img>
            </v-col>

            <v-col cols="12" class="text-center mt-3 mb-1">
              <p class="mb-0 text-sm text-regular">
                You have requested a free demo
                <span class="text-semibold" v-text="page"></span>
              </p>
              <p class="mt-1 text-sm text-regular hidden-md-and-down">
                Free demo will be sent to your email
              </p>
              <p class="mt-1 text-md text-regular hidden-lg-and-up">
                Free demo will be sent to your email
              </p>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="bottomDialog" class="top" @click="returnData()">
      <v-sheet class="text-center py-10 radius-top">
        <v-row class="p-0" fill-height justify="center" align="center">
          <v-col cols="12">
            <p class="dialog-title mb-2 text-center">
              Your request has been sent
            </p>
          </v-col>

          <v-col cols="12" align="center">
            <v-img
              width="90px"
              height="90px"
              :src="require('../assets/icon/success.svg')"
            ></v-img>
          </v-col>

          <v-col cols="12" class="text-center mt-3 mb-1">
            <p class="mb-0 text-md color--black text-regular">
              You have requested a free demo
              <span class="text-semibold" v-text="page"></span>
            </p>
            <p class="mt-1 text-md color--black text-regular">
              Free demo will be sent to your email
            </p>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
    <!-- End Dialog Pop Up -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  props: {
    dialog2: Boolean,
    bottomDialog: Boolean,
    page: String,
  },

  methods: {
    returnData() {
      this.dialog2 = false;
      this.bottomDialog = false;
      this.$emit("dialog2", this.dialog2);
      this.$emit("bottomDiaog", this.bottomDialog);
    },
  },
};
</script>

<style scoped>
.dialog-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  color: #000000;
}

.radius-top {
  border-radius: 20px 20px 0 0;
}

.top {
  z-index: 1000 !important;
}

.icon-product {
  height: 65px;
  width: 65px;
}

div >>> .v-dialog {
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
  .dialog-title {
    font-size: 20px;
    line-height: 20px;

    color: #000000;
  }
}

@media only screen and (max-width: 360px) {
  .dialog-title {
    font-size: 18px;
    line-height: 20px;

    color: #000000;
  }

  .icon-product {
    height: 52px;
    width: 52px;
  }
}
</style>
