import { render, staticRenderFns } from "./DialogNotif.vue?vue&type=template&id=982c5d56&scoped=true&"
import script from "./DialogNotif.vue?vue&type=script&lang=js&"
export * from "./DialogNotif.vue?vue&type=script&lang=js&"
import style0 from "./DialogNotif.vue?vue&type=style&index=0&id=982c5d56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "982c5d56",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VCard,VCardTitle,VCol,VDialog,VImg,VRow,VSheet})
