<template>
  <div id="main" class="mt-md-16 pt-10">
    <v-container class="mt-4 pt-10 container-xl mb-16 pb-16">
      <p class="title-about text-center mb-16">Gallery</p>
      <div class="gallery-container">
        <div
          v-for="(data, index) in gallery"
          :key="`gallery-item-${index}`"
          class="gallery-item"
          :class="{
            'vertical': data.size ==='vertical',
            'normal': data.size === 'normal',
            'horizontal': data.size === 'horizontal',
            'big': data.size === 'big',
            'full-hover': data.type !== 'image',
          }"
          @click="dialogPreview = true; dataPreview = data"
        >
          <img v-if="data.type == 'image'" :src="require(`../assets/gallery/${data.src}`)" />
          <vue-plyr v-else>
            <video
              crossorigin
              :src="require(`../assets/gallery/${data.src}`)"
            />
          </vue-plyr>
          <p class="gallery-desc">
            {{ data.title }}
          </p>
        </div>
      </div>
      <v-overlay v-if="dialogPreview" z-index="99">
        <div style="max-width: 90vw">
          <div class="d-flex w-100 justify-space-between mb-1">
            <p class="text-14 white--text text-bold">
              {{ dataPreview.title }}
            </p>
            <v-btn icon @click="dialogPreview = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </div>
          <div v-if="dataPreview.type == 'image'">
            <img style="max-width: 90vw; height: auto; max-height: 90vh" :src="require(`../assets/gallery/${dataPreview.src}`)" />
          </div>
          <vue-plyr v-else>
            <video
              controls
              crossorigin
              playsinline
              :src="require(`../assets/gallery/${dataPreview.src}`)"
            />
          </vue-plyr>
        </div>
      </v-overlay>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import { galleryList } from "../constant/gallery";

export default {
  name: "Gallery",
  components: {
    Footer,
  },
  data() {
    return {
      gallery: [],
      dialogPreview: false,
      dataPreview: {},
    };
  },
  mounted() {
    console.log(galleryList);
    this.gallery = [];
    galleryList?.map((item) => {
      const data = item.files.map(
        (file, index) => {
          return {...file, type: item.type, title: `${item.title} #${index+1}`}
        })
      this.gallery = [...this.gallery, ...data]
    })
  },
  methods: {
    openMaps: function() {
      window.open(
        "https://www.google.com/maps/place/PT.+HEADWAY/@-8.5978297,115.2147183,15z/data=!4m5!3m4!1s0x0:0x42bd7765aa9b98c4!8m2!3d-8.5978297!4d115.2147183",
        "_blank"
      );
    },

    openBranchMaps(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
@import "../styles/css/style.css";

.title-about {
  font-family: Montserrat !important;
  font-weight: bold;
  font-size: 31px;
  line-height: 38px;
}

.gallery-container {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-auto-rows: 15rem;
  grid-auto-flow: dense;
}

.gallery-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  overflow: hidden;
  border-radius: 8px;
}

.gallery-desc {
  position: absolute;
  bottom: 0;
  color: white;
  text-align: left;
  padding: 16px;
  margin: 28px;
  height: 24px !important;
  font-size: 14px;
  font-weight: 600;
  z-index: 2;
  text-shadow: 0 4px 12px #000000;
}

.gallery-item::after {
  content: "";
  background: linear-gradient(transparent,black);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateY(70%);
  transition-duration: .75s;
}

.gallery-item:hover::after {
  transform: translateY(10%);
}
.full-hover:hover::after {
  transform: translateY(0%);
}
.gallery-item:hover .gallery-desc{
  color: white;
}

.gallery-container > .gallery-item > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 1s;
}

.gallery-container > .gallery-item:hover > img {
  transform: scale(1.05);
}

.horizontal {
  grid-column: span 2;
}

.vertical {
  grid-row: span 2;
}

.big {
  grid-column: span 2;
  grid-row: span 2;
}

@media only screen and (max-width: 600px) {
  .horizontal {
    grid-column: unset;
    grid-row: unset;
  }

  .vertical {
    grid-column: unset;
    grid-row: unset;
  }

  .big {
    grid-column: unset;
    grid-row: unset;
  }
}
</style>
