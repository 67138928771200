<template>
  <div id="main">
    <div id="content">
      <v-img
        class="mx-auto img-header"
        :src="require('../../assets/icon/logo_sid.png')"
      ></v-img>

      <v-container class="container-xl text-center">
        <p class="header-title">
          Implementation of <br />
          "Sistem Informasi Desa"
        </p>
        <p class="sub-title">
          Organize the structure data of communities for village government
          officials to providing best services to public
        </p>
        <v-btn
          class="mt-12 mb-16 btn-primary btn-try"
          elevation="0"
          @click="(dialog = true), (bottomDialog = false), (dialog2 = false)"
          >Try free demo</v-btn
        >
      </v-container>

      <v-container fluid class="mt-0 px-0">
        <v-card class="card-preview" elevation="0">
          <v-card-actions class="justify-center hidden-sm-and-down">
            <v-item-group
              v-model="onboarding"
              class="text-center d-inline-flex mb-9"
              mandatory
            >
              <v-item
                v-for="n in onboardTitle"
                :key="n"
                v-slot="{ active, toggle }"
                class="item-spacer"
              >
                <div
                  :input-value="active"
                  style="cursor: pointer"
                  align="center"
                  @click="toggle"
                >
                  <p class="text-medium text-menu-preview" v-text="n"></p>
                  <hr />
                </div>
              </v-item>
            </v-item-group>
          </v-card-actions>
          <v-card-actions class="justify-center hidden-md-and-up">
            <v-slide-group
              v-model="onboarding"
              class="text-center d-inline-flex mb-5"
              mandatory
            >
              <v-slide-item
                v-for="n in onboardTitle"
                :key="n"
                v-slot="{ active, toggle }"
                class="item-spacer"
              >
                <div :input-value="active" align="center" @click="toggle">
                  <p class="text-medium text-menu-preview" v-text="n"></p>
                  <hr />
                </div>
              </v-slide-item>
            </v-slide-group>
          </v-card-actions>
          <v-window v-model="onboarding" class="window-border">
            <div class="arrows d-none d-lg-block">
              <div class="arrow-prev">
                <v-btn class="btn-arrow-window" @click="prev">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div class="arrow-next">
                <v-btn class="btn-arrow-window" @click="next">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <v-window-item v-for="n in dataWindows" :key="n" align="center">
              <viewer class="images" :options="viewOption" v-viewer>
                <img
                  class="img-windows"
                  :src="require('../../assets/sid/' + n)"
                />
              </viewer>
            </v-window-item>
          </v-window>
        </v-card>
      </v-container>

      <v-container class="container-xl">
        <p class="second-title text-center">Features</p>
        <v-row class="mt-11 feature-item-spacer mx-auto d-none d-sm-flex">
          <v-col cols="12 px-16" style="display: contents !important">
            <v-card
              v-for="i in features"
              :key="i"
              elevation="0"
              class="card-features"
            >
              <v-img
                :width="i.width"
                height="32px"
                class="mx-md-0 mx-sm-0 mx-auto"
                :src="require('../../assets/icon/' + i.icon)"
              ></v-img>
              <p
                class="feature-title text-md-left text-sm-left text-center"
                v-text="i.title"
              ></p>
              <p
                class="feature-desc text-md-left text-sm-left text-center"
                v-text="i.desc"
              ></p>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-11 feature-item-spacer mx-auto hidden-sm-and-up">
          <v-col cols="12" style="display: contents !important" class="mx-auto">
            <v-card
              v-for="(i, index) in 4"
              :key="index"
              elevation="0"
              class="card-features"
            >
              <v-img
                width="32px"
                height="32px"
                class="mx-md-0 mx-sm-0 mx-auto"
                :src="require('../../assets/icon/' + features[index].icon)"
              ></v-img>
              <p
                class="feature-title text-md-left text-sm-left text-center"
                v-text="features[index].title"
              ></p>
              <p
                class="feature-desc text-md-left text-sm-left text-center"
                v-text="features[index].desc"
              ></p>
            </v-card>
            <v-expand-transition>
              <div v-show="expand">
                <v-card
                  v-for="i in features.slice(4)"
                  :key="i"
                  elevation="0"
                  class="card-features"
                >
                  <v-img
                    width="32px"
                    height="32px"
                    class="mx-md-0 mx-sm-0 mx-auto"
                    :src="require('../../assets/icon/' + i.icon)"
                  ></v-img>
                  <p
                    class="feature-title text-md-left text-sm-left text-center"
                    v-text="i.title"
                  ></p>
                  <p
                    class="feature-desc text-md-left text-sm-left text-center"
                    v-text="i.desc"
                  ></p>
                </v-card>
              </div>
            </v-expand-transition>
            <p
              class="mx-auto text-medium color--primary"
              @click="expand = !expand"
            >
              <span v-text="expand ? 'Less features' : 'More features'"></span>
              <v-icon
                color="#0369B3"
                :class="{
                  'rotate-180deg': expand,
                }"
                >mdi-chevron-down</v-icon
              >
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-img
        width="100%"
        :src="require('../../assets/background/Rectangle 61.svg')"
        class="text-center img-try d-none d-sm-flex"
      >
        <p class="last-title">Do you want to try it first?</p>
        <v-btn class="btn-primary btn-try-2" @click="dialog = true"
          >Try free demo</v-btn
        >
      </v-img>

      <v-container fluid fill-height class="mt-16 hidden-sm-and-up">
        <v-row fill-height class="border-try py-5">
          <v-col cols="12">
            <p class="text-medium color--white my-auto text-center">
              Do you want to try it first?
            </p>
          </v-col>
          <v-col cols="12" align="center">
            <v-btn class="btn-primary btn-try-2 my-auto" @click="dialog = true">
              Try free demo
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- Dialog Pop Up -->
      <dialog-try
        v-if="dialog == true"
        :dialog="dialog"
        @dialog="dialog = false"
        :img="imgDialog"
        :page="pageName"
      />

      <dialog-notif
        :dialog2="dialog2"
        :bottomDialog="bottomDialog"
        @dialog2="dialog2 = false"
        @bottomDialog="bottomDialog = false"
        :page="pageName"
      />
      <!-- End Dialog Pop Up -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import DialogTry from "../../components/DialogTry.vue";
import DialogNotif from "../../components/DialogNotif.vue";

export default {
  name: "Donya",
  components: {
    Footer,
    DialogTry,
    DialogNotif,
  },
  data() {
    return {
      expand: false,
      onboarding: 0,
      length: 5,
      onboardTitle: [
        "Dashboard",
        "News & Service",
        "Organize Letters",
        "Resident Profile",
        "Population Data",
      ],
      dataWindows: [
        "dashboard.png",
        "news.png",
        "organize.png",
        "resident.png",
        "population.png",
      ],
      features: [
        {
          width: "32px",
          icon: "analytics.svg",
          title: "Mentoring & Training",
          desc:
            "Get training on using this system and online or offline assistance",
        },
        {
          width: "32px",
          icon: "icon-park-outline_doc-search-two.svg",
          title: "Audit Trail",
          desc:
            "Record all activities carried out by each user in detail starting from adding, changing and deleting data",
        },
        {
          width: "32px",
          icon: "database.svg",
          title: "Database Management",
          desc: "Each user can manage data according to their regions",
        },
        {
          width: "32px",
          icon: "extension-puzzle-outline.svg",
          title: "Go Customization",
          desc: "Customize the system according to your needs",
        },
        {
          width: "32px",
          icon: "arrow-up-circle.svg",
          title: "Stay Updated",
          desc: "Get updates on the latest feature from us",
        },
        {
          width: "32px",
          icon: "settings.svg",
          title: "User Access Customization",
          desc:
            "Setting access rights that can be made yourself according to the needs of each divisions",
        },
      ],

      dialog: false,
      imgDialog: "logo_sid.png",
      pageName: "SID",

      viewOption: {
        button: true,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: false,
      },
    };
  },
  mounted() {
    this.$root.$on("dialog2", () => {
      this.dialog2 = true;
    });

    this.$root.$on("bottomDialog", () => {
      this.bottomDialog = true;
    });
  },
  methods: {
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
  },
};
</script>

<style scoped>
@import "../../styles/css/style.css";
@import "../../styles/css/product.css";

.card-preview {
  width: 100%;
  min-height: 962px;
  padding: 110px 146px 110px 146px;
  border-radius: 64px !important;

  background: #f2faff;
}

.img-windows {
  border-radius: 25px !important;
  box-shadow: (2px 2px 18px rgba(0, 0, 0, 0.1));
  max-width: 100% !important;
  object-fit: contain !important;
}

.img-header {
  height: 109px;
  width: 108px;
}

@media only screen and (max-width: 1080px) {
  .card-preview {
    min-height: auto;
    padding: 24px 16px;
    border-radius: 20px !important;
  }
}

@media only screen and (max-width: 960px) {
  .card-preview {
    min-height: auto;
    padding: 24px 16px;
    border-radius: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  .img-header {
    height: 82px;
    width: 81px;
  }
}
</style>
