import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueNumber from "vue-number-animation";
import VuePlyr from 'vue-plyr'
// import axios from 'axios';
// import VueAxios from 'vue-axios';
import AxiosPlugin from 'vue-axios-cors';
import 'vue-plyr/dist/vue-plyr.css'

Vue.use(VueNumber);
Vue.use(VueViewer);
// Vue.use(VueAxios, axios);
Vue.use(AxiosPlugin);
Vue.use(VuePlyr);

Vue.config.productionTip = false

Vue.prototype.$tes = true

new Vue({
  created() {
    AOS.init()
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
