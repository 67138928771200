<template>
  <div id="main" v-if="info != null">
    <div class="mb-16">
      <v-container class="container-xl header-section">
        <p class="header-title">
          We are <span class="color--primary">PT HEKSA ADHITEK WAYATAMA.</span>
        </p>
        <v-row>
          <v-col cols="12" sm="10" md="11" lg="9">
            <p
              class="text-lg text-regular mt-8"
              style="line-height: 28px"
              v-text="info.about_description"
            ></p>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="px-0 second-content-section">
        <v-img :src="require('../assets/background/header_about.png')"></v-img>
      </v-container>

      <v-container class="text-center container-xl third-content-section">
        <h3>Our Vision</h3>
        <p class="visi-text" v-text="info.about_our_vision"></p>
      </v-container>

      <div class="timeline-container">
        <v-container class="hidden-sm-and-down container-xl">
          <h3 class="text-center color--white mb-12">
            Our Journey
          </h3>
          <v-stepper
            class="bg-step"
            alt-labels
            v-model="step"
            elevation="0"
            rounded="0"
          >
            <v-stepper-header flat elevation="0">
              <v-stepper-step
                :complete="step == 1"
                :class="{ stepSelect: s1 }"
                class="pointer"
                complete-icon=""
                step=""
                @click="step1"
              >
                <p class="mx-auto mb-2 text-medium text-lg color--white">
                  2004
                </p>
                <p
                  class="mx-auto text-medium text-sm label-stepper"
                  :class="{ labelSelect: sd1 }"
                >
                  {{ timelines[0].title }}
                </p>
              </v-stepper-step>
              <v-divider :class="{ lineSelect: s2 }"></v-divider>
              <v-stepper-step
                :complete="step == 2"
                :class="{ stepSelect: s2 }"
                class="pointer"
                complete-icon=""
                step=""
                @click="step2"
              >
                <p class="mx-auto mb-2 text-medium text-lg color--white">
                  2009
                </p>
                <p
                  class="mx-auto text-medium text-sm label-stepper"
                  :class="{ labelSelect: sd2 }"
                >
                  {{ timelines[1].title }}
                </p>
              </v-stepper-step>
              <v-divider :class="{ lineSelect: s3 }"></v-divider>
              <v-stepper-step
                :complete="step == 3"
                :class="{ stepSelect: s3 }"
                class="pointer"
                complete-icon=""
                step=""
                @click="step3"
              >
                <p class="mx-auto mb-2 text-medium text-lg color--white">
                  2014
                </p>
                <p
                  class="mx-auto text-medium text-sm label-stepper"
                  :class="{ labelSelect: sd3 }"
                >
                  {{ timelines[2].title }}
                </p>
              </v-stepper-step>
              <v-divider :class="{ lineSelect: s4 }"></v-divider>
              <v-stepper-step
                :complete="step == 4"
                :class="{ stepSelect: s4 }"
                class="pointer"
                complete-icon=""
                step=""
                @click="step4"
              >
                <p class="mx-auto mb-2 text-medium text-lg color--white">
                  2019
                </p>
                <p
                  class="mx-auto text-medium text-sm label-stepper"
                  :class="{ labelSelect: sd4 }"
                >
                  {{ timelines[3].title }}
                </p>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items class="bg-step">
              <v-stepper-content
                v-for="s in timelines"
                :key="s"
                :step="s.id + 1"
                class="bg-step"
              >
                <p
                  class="text-lg text-center color--white text-timeline"
                  v-text="s.desc"
                ></p>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>

        <v-container class="hidden-md-and-up">
          <h2 class="text-center color--white">
            Our Journey
          </h2>

          <v-stepper
            v-model="step"
            class="bg-step mt-14"
            vertical
            elevation="0"
            rounded="0"
          >
            <v-stepper-step
              :complete="step == 1"
              :class="{ stepSelect: s1 }"
              complete-icon=""
              step=""
              @click="step1"
            >
              <p class="text-semibold text-lg color--white mb-1">
                2004
              </p>
              <p
                class="text-medium text-md text-left label-stepper mb-0"
                :class="{ labelSelect: sd1 }"
              >
                {{ timelines[0].title }}
              </p>
            </v-stepper-step>

            <v-stepper-content
              step="1"
              class="bg-step pb-10"
              :class="{ contentSelect: s2 }"
            >
              <p
                class="color--white text-timeline text-lg text-normal"
                v-text="timelines[0].desc"
              ></p>
            </v-stepper-content>

            <v-stepper-step
              :complete="step == 2"
              :class="{ stepSelect: s2 }"
              complete-icon=""
              step=""
              @click="step2"
            >
              <p class="mb-1 text-semibold text-lg color--white">
                2009
              </p>
              <p
                class="text-medium text-md text-left label-stepper mb-0"
                :class="{ labelSelect: sd2 }"
              >
                {{ timelines[1].title }}
              </p>
            </v-stepper-step>

            <v-stepper-content
              step="2"
              class="bg-step pb-10"
              :class="{ contentSelect: s3 }"
            >
              <p
                class="color--white text-timeline text-lg"
                v-text="timelines[1].desc"
              ></p>
            </v-stepper-content>

            <v-stepper-step
              :complete="step == 3"
              :class="{ stepSelect: s3 }"
              complete-icon=""
              step=""
              @click="step3"
            >
              <p class="mb-1 text-semibold text-lg color--white">
                2014
              </p>
              <p
                class="text-medium mb-0 text-left text-md label-stepper"
                :class="{ labelSelect: sd3 }"
              >
                {{ timelines[2].title }}
              </p>
            </v-stepper-step>

            <v-stepper-content
              step="3"
              class="bg-step pb-10"
              :class="{ contentSelect: s4 }"
            >
              <p
                class="color--white text-timeline text-lg"
                v-text="timelines[2].desc"
              ></p>
            </v-stepper-content>

            <v-stepper-step
              :complete="step == 4"
              :class="{ stepSelect: s4 }"
              complete-icon=""
              step=""
              @click="step4"
            >
              <p class="mb-1 text-semibold text-lg color--white">
                2019
              </p>
              <p
                class="mb-0 text-medium text-md text-left label-stepper"
                :class="{ labelSelect: sd4 }"
              >
                {{ timelines[3].title }}
              </p>
            </v-stepper-step>

            <v-stepper-content step="4" class="bg-step pb-10">
              <p
                class="color--white text-timeline text-lg"
                v-text="timelines[3].desc"
              ></p>
            </v-stepper-content>
          </v-stepper>
        </v-container>
      </div>

      <v-container fluid class="pa-0">
        <v-img
          :src="require('../assets/background/Rectangle 70.svg')"
          width="100%"
          class="text-center img-bg-bottom"
        >
          <v-img
            :src="require('../assets/background/bottom-img-about.png')"
          ></v-img>
          <h4 class="mt-4 mt-sm-13 color--white">
            Have any questions?
          </h4>
          <p class="text-sub1 text-medium color--white mt-3 mt-sm-5">
            Let's have a chat
          </p>
          <v-btn
            class="btn-contact mt-3 mt-sm-6 mb-sm-10 mb-10 mb-md-10"
            elevation="0"
            @click="openChat()"
            >Contact Us</v-btn
          >
        </v-img>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";

export default {
  name: "About",
  components: {
    Footer,
  },

  data() {
    return {
      selected: false,
      step: 1,
      s1: true,
      s2: false,
      s3: false,
      s4: false,
      sd1: true,
      sd2: false,
      sd3: false,
      sd4: false,
      info: null,
      timelines: [
        {
          id: 0,
          year: 2004,
          expand: true,
          color: "#F2FAFF",
          title: "Establishment of LKP Wahana Bahasa",
          desc:
            "LKP Wahana Bahasa, provides foreign language courses (English, Japanese, Italian and German), in-house training and language competency certification.",
        },
        {
          id: 1,
          year: 2009,
          expand: false,
          color: "#185E9E",
          title: "Establishment of Cemara Mas Education Foundation & CV Reksa.",
          desc:
            "Cemara Mas Education Foundation with life skills training, PAUD Widya Bhakti (Kindergarten) and Daycare in North Denpasar District.",
        },
        {
          id: 2,
          year: 2014,
          expand: false,
          color: "#185E9E",
          title: "Launching Rexatech Service",
          desc:
            "Rexatech, Software Development Service, Launching of HeadWay Test system in CBT",
        },
        {
          id: 3,
          year: 2019,
          expand: false,
          color: "#185E9E",
          title: "Establishment of PT Headway ",
          desc:
            "PT HEADWAY, Heksa Adhitek Wayatama with HeadWay Testing Services & HeadWay IT Services",
        },
      ],

      images: [
        "about_last (1).svg",
        "about_last (3).svg",
        "about_last (2).svg",
      ],
    };
  },

  created() {
    this.getData();
  },

  methods: {
    selectIndex: function(index, expand) {
      this.timelines[index].expand = !expand;
      if (this.timelines[index].expand == true) {
        this.timelines[index].color = "#F2FAFF";
      } else {
        this.timelines[index].color = "#185E9E";
      }
    },

    step1: function() {
      this.s1 = true;
      this.s2 = false;
      this.s3 = false;
      this.s4 = false;
      this.step = 1;
      this.sd1 = true;
      this.sd2 = false;
      this.sd3 = false;
      this.sd4 = false;
    },

    step2: function() {
      this.s1 = true;
      this.s2 = true;
      this.s3 = false;
      this.s4 = false;
      this.step = 2;
      this.sd1 = false;
      this.sd2 = true;
      this.sd3 = false;
      this.sd4 = false;
    },

    step3: function() {
      this.s1 = true;
      this.s2 = true;
      this.s3 = true;
      this.s4 = false;
      this.step = 3;
      this.sd1 = false;
      this.sd2 = false;
      this.sd3 = true;
      this.sd4 = false;
    },

    step4: function() {
      this.s1 = true;
      this.s2 = true;
      this.s3 = true;
      this.s4 = true;
      this.step = 4;
      this.sd1 = false;
      this.sd2 = false;
      this.sd3 = false;
      this.sd4 = true;
    },

    getData() {
      this.info = JSON.parse(localStorage.getItem("dataDescription"));
    },

    openChat() {
      document.querySelector(".woot--bubble-holder").click();
    },
  },
};
</script>

<style scoped>
@import "../styles/css/style.css";

.header-title {
  font-weight: 600;
  font-size: 38px;
  line-height: 62px;
  letter-spacing: -0.01em;

  color: #000000;
}

.header-section {
  padding-left: 72px;
  margin-top: 180px;
}

.journey {
  padding-left: 150px;
}

.bottom-img {
  width: 33.33%;
  height: 684px;
}

.visi-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26.22px;
  margin-top: 20px;
  padding: 0px 200px;
}

.theme--light.v-timeline:before {
  background: #185e9e;
}

.btn-contact {
  width: 246px !important;
  height: 54px !important;
  border: 1px solid #ffffff !important;
  background: transparent !important;
  border-radius: 32px !important;
  color: #ffffff;
  text-transform: none !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;

  font-weight: 600 !important;
}

.btn-contact:hover {
  background: #ffffff !important;
  color: #3f85d3 !important;
}

.fade-in {
  opacity: 0;
  transition: 0.3s all ease-out;
  transform: scale(0.8);
}

.timeline-container {
  padding: 60px 0px;
  margin-top: 200px;
  margin-bottom: 100px;
  background: #004d93;
}

div >>> .v-stepper__header {
  background: #004d93;
}

div >>> .v-stepper__step__step {
  background: #185e9e !important;
}

div >>> .stepSelect .v-stepper__step__step {
  background: #f2faff !important;
}

div >>> .v-divider {
  margin: 35px -150px 0 -150px !important;
  background: #185e9e;
  border: 2px solid #185e9e;
  border-color: #185e9e !important;
}

div >>> .lineSelect {
  background: #f2faff !important;
  border: 2px solid #f2faff !important;
  border-color: #f2faff !important;
}

.bg-step {
  background: #004d93 !important;
}

div >>> .v-stepper__label {
  text-align: center;
  color: white;
  width: 250px;
}

.label-stepper {
  color: #82b5e3;
}

.labelSelect {
  color: #ffffff;
}

.text-timeline {
  padding-top: 32px;
  padding-inline: 150px;
  line-height: 29.05px;
}

div >>> .v-timeline-item__inner-dot {
  border: none !important;
}

.contentSelect {
  border-left: 1px solid #f2faff !important;
}

.img-bg-bottom {
  height: auto !important;
}

.second-content-section {
  margin-top: 70px;
}

.third-content-section {
  margin-top: 150px;
}

h4 {
  font-size: 49px;
}

.text-sub1 {
  font-size: 22px;
}

@media only screen and (min-width: 1904px) {
  .container-xl {
    padding: 0px 250px;
  }
}

@media only screen and (max-width: 1263px) {
  div >>> .v-stepper__label {
    width: 200px;
  }

  div >>> .v-divider {
    margin: 35px -120px 0 -120px !important;
  }
}

@media only screen and (max-width: 1080px) {
  div >>> .v-stepper__label {
    width: 150px;
  }

  div >>> .v-divider {
    margin: 35px -100px 0 -100px !important;
  }

  .text-timeline {
    padding-top: 10px;
    padding-inline: 80px;
  }
}

@media only screen and (max-width: 960px) {
  .visi-text {
    padding: 0px 40px;
  }

  .timeline-container {
    padding: 64px;
    margin-top: 68px;
    margin-bottom: 27px;
    background: #004d93;
  }

  div >>> .v-stepper__label {
    text-align: center;
    color: white;
    width: auto;
  }

  .text-timeline {
    padding-top: 0px;
    padding-inline: 0px;
  }

  div >>> .v-stepper__step {
    padding-bottom: 0;
    padding-top: 0px;
  }

  .bottom-img {
    height: 450px;
  }

  .header-section {
    margin-top: 118px;
  }

  .second-content-section {
    margin-top: 36px;
  }

  .third-content-section {
    margin-top: 48px;
  }

  h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .header-section {
    padding-left: 20px;
  }

  .journey {
    padding-left: 0px;
    text-align: center;
  }

  .bottom-img {
    width: 33.33%;
    height: 254px;
  }

  h4 {
    font-size: 30px !important;
  }

  .text-sub1 {
    font-size: 16px !important;
  }

  .btn-contact {
    width: 170px !important;
    height: 45px !important;
    font-size: 16px;
  }

  .visi-text {
    padding: 0px 20px;
  }

  .timeline-container {
    padding: 32px 8px;
  }

  div >>> .v-stepper__label {
    text-align: center;
    color: white;
    width: auto;
  }

  .text-timeline {
    padding-top: 0px;
    padding-inline: 0px;
    line-height: 25px;
  }

  div >>> .v-stepper__step {
    padding-bottom: 0;
    padding-top: 0px;
  }

  .header-title {
    font-size: 38px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 360px) {
  .header-title {
    font-size: 30px;
    line-height: 27px;
  }
}
</style>
