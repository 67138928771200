<template>
  <div id="main">
    <div
      class="py-xl-16 my-xl-16 py-lg-16 my-lg-16 py-md-16 my-md-16 py-sm-1 my-sm-10 py-8 my-4"
    >
      <v-container class="container-xl container-sm mt-16">
        <v-row class="ma-0">
          <v-col cols="12" class="mb-4 mb-md-16">
            <p class="header-title">HeadWay IT Service</p>
            <p class="sub-title">
              We focus on information and communication technology
              implementation <br class="d-none d-md-inline" />
              services that make it easier for you to manage your business.
            </p>
          </v-col>
          <v-col cols="12" class="product-large ma-0">
            <v-card
              v-for="i in products"
              :key="i"
              class="card-product"
              elevation="0"
              :to="i.link"
            >
              <v-row>
                <v-col cols="3" xl="3" lg="3" md="3" sm="3">
                  <v-img
                    :width="i.width"
                    :height="i.height"
                    contain
                    :aspect-ratio="1 / 1"
                    :src="require('../../assets/icon/' + i.img)"
                    class="d-none d-sm-flex"
                  ></v-img>
                  <v-img
                    width="80px"
                    height="80px"
                    contain
                    :aspect-ratio="1 / 1"
                    :src="require('../../assets/icon/' + i.img)"
                    class="hidden-sm-and-up"
                  ></v-img>
                </v-col>
                <v-col cols="9" xl="9" lg="9" md="9" sm="9">
                  <p
                    class="card-menu-title mb-2"
                    v-text="i.title"
                    :to="i.link"
                  ></p>
                  <p class="card-menu-desc" v-text="i.desc"></p>
                  <div class="link hidden-sm-and-down">
                    <router-link class="card-menu-link" :to="i.link"
                      >Learn more</router-link
                    >
                    <v-divider width="94px"></v-divider>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";

export default {
  name: "HeadWay IT Service",
  components: {
    Footer,
  },
  data() {
    return {
      products: [
        {
          img: "logo_donya.png",
          width: "108px",
          height: "108px",
          title: "Donya",
          link: "/products/headway-it-service/donya",
          desc:
            "A powerful ERP software that integrates your data thoroughly, provide your data and reports in real-time",
        },
        {
          img: "logo_manpro.png",
          width: "109px",
          height: "119px",
          title: "Manpro",
          link: "/products/headway-it-service/manpro",
          desc:
            "Managing administration, finance, and keep updated with project developments in the field in real-time",
        },
        {
          img: "logo_sid.png",
          width: "85px",
          height: "85px",
          title: `Implementation of "Sistem Informasi Desa"`,
          link:
            "/products/headway-it-service/implementation-of-sistem-informasi-desa",
          desc:
            "Organize the structure data of communities for village goverment officials to providing best services to public",
        },
      ],
    };
  },
};
</script>

<style scoped>
@import "../../styles/css/style.css";
.header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 39px;
  line-height: 48px;

  color: #000000;
}

.sub-title {
  line-height: 26px;
  margin: 0 !important;
}

.card-product {
  width: 492px;
  min-height: 185px;
  padding: 24px;
  margin: 0px 64px 32px 0px;
  border: 1px solid transparent;
}

.card-product:hover {
  border: 1px solid #a9e1ff;
  box-shadow: 24px 20px 55px rgba(0, 0, 0, 0.03);
  border-radius: 32px;
}

.card-menu-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #0369b3;
}

.card-menu-desc {
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5a;
}

.card-menu-link {
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  color: #0369b3;
}

.theme--light.v-divider {
  border-color: #0369b3 !important;
  height: 2px !important;
}

.link:hover .card-menu-link {
  color: #5c9ceb;
}

.link:hover .theme--light.v-divider {
  border-color: #5c9ceb !important;
}

.product-large {
  display: contents !important;
}

@media only screen and (min-width: 1904px) {
  .container-xl {
    padding: 0px 250px;
  }
}

@media only screen and (max-width: 960px) {
  .card-product {
    min-height: 80px;
    padding: 16px;
    margin: 24px;
    border: 1px solid #a9e1ff;
    box-shadow: 24px 20px 55px rgba(0, 0, 0, 0.03);
    border-radius: 32px;
  }

  .container-sm {
    padding-inline: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .card-product {
    min-height: 80px;
    width: auto;
    padding: 16px;
    margin: 8px 8px;
    border-radius: 20px;
  }

  .card-menu-title {
    font-size: 20px;
    line-height: 20px;
  }

  .container-sm {
    padding-inline: 8px;
  }

  .sub-title {
    font-size: 16px;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 360px) {
  .sub-title {
    font-size: 14px;
  }
}
</style>
