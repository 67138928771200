<template>
  <div>
    <v-overlay v-model="dialog" persistent elevation="0" class="top">
      <v-card
        v-resize="onResize"
        class="top px-4"
        max-width="508px"
        :height="windowSize.x < 600 ? '100vh' : ''"
        :rounded="windowSize.x < 600 ? '' : 'xl'"
        light
      >
        <v-img
          @click="returnData()"
          height="16px"
          width="16px"
          :src="require('../assets/icon/x.svg')"
          class="float-right mt-4 mr-sm-0 mr-1 pointer"
        ></v-img>
        <v-card-title class="pt-6 pr-3 pb-8 pl-8 ">
          <v-row class="pa-0" fill-height justify="center" align="center">
            <v-col cols="8">
              <p class="dialog-title mb-0">Request a Demo</p>
              <p v-if="page === 'SID'" class="dialog-title pr-2">
                Sistem Informasi Desa
              </p>
              <p v-else class="dialog-title" v-text="page"></p>
            </v-col>
            <v-col cols="4">
              <v-img
                class="float-right icon-product"
                contain
                :src="require('../assets/icon/' + img)"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="ma-1 ma-sm-4"
          >
            <v-row class="ma-0">
              <v-col cols="6" md="6" class="form-column">
                <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  outlined
                  name="first"
                  flat
                  required
                  @keyup="showErrorName(firstname)"
                  :append-icon="showNameIcon ? 'mdi-alert-circle-outline' : ''"
                  @blur="showErrorName(firstname)"
                  placeholder="First Name*"
                  class="input-field"
                />
              </v-col>

              <v-col cols="6" md="6" class="form-column">
                <v-text-field
                  v-model="lastname"
                  outlined
                  flat
                  placeholder="Last Name"
                  class="input-field"
                />
              </v-col>

              <v-col cols="6" md="6" class="form-column">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  @keyup="showErrorEmail(email)"
                  :append-icon="showEmailIcon ? 'mdi-alert-circle-outline' : ''"
                  @blur="showErrorEmail(email)"
                  outlined
                  flat
                  required
                  placeholder="Email*"
                  class="input-field"
                />
              </v-col>

              <v-col cols="6" md="6" class="form-column">
                <v-autocomplete
                  v-model="jobTitle"
                  :items="jobTitles"
                  :rules="[(v) => !!v || '*Please fill your job title']"
                  ref="jobAutocomplete"
                  outlined
                  flat
                  append-icon="mdi-chevron-down"
                  required
                  placeholder="Job Title*"
                  class="input-field"
                  :disable-lookup="true"
                />
              </v-col>

              <v-col cols="6" md="6" class="form-column">
                <v-text-field
                  v-model="company"
                  :rules="companyRules"
                  @keyup="showErrorCompany(company)"
                  :append-icon="
                    showCompanyIcon ? 'mdi-alert-circle-outline' : ''
                  "
                  @blur="showErrorCompany(company)"
                  outlined
                  flat
                  required
                  placeholder="Company*"
                  class="input-field"
                />
              </v-col>

              <v-col cols="6" md="6" class="form-column">
                <v-autocomplete
                  v-model="employee"
                  :items="employees"
                  :rules="[(v) => !!v || '*Please fill your employee']"
                  ref="employeeAutocomplete"
                  outlined
                  flat
                  required
                  append-icon="mdi-chevron-down"
                  placeholder="Employee*"
                  class="input-field"
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="form-column"
                v-if="page != 'Donya'"
              >
                <v-text-field
                  v-model="mobileNumber"
                  :rules="mobileRules"
                  @keyup="showErrorNumber(mobileNumber)"
                  :append-icon="
                    showNumberIcon ? 'mdi-alert-circle-outline' : ''
                  "
                  @blur="showErrorNumber(mobileNumber)"
                  outlined
                  flat
                  required
                  placeholder="Mobile Number*"
                  class="input-field"
                />
              </v-col>
              <v-col cols="6" md="6" class="form-column" v-if="page == 'Donya'">
                <v-text-field
                  v-model="mobileNumber"
                  :rules="mobileRules"
                  @keyup="showErrorNumber(mobileNumber)"
                  :append-icon="
                    showNumberIcon ? 'mdi-alert-circle-outline' : ''
                  "
                  @blur="showErrorNumber(mobileNumber)"
                  outlined
                  flat
                  required
                  placeholder="Mobile Number*"
                  class="input-field"
                />
              </v-col>
              <v-col cols="6" md="6" class="form-column" v-if="page == 'Donya'">
                <v-autocomplete
                  v-model="industry"
                  :items="industries"
                  :rules="[(v) => !!v || '*Please fill your industry']"
                  ref="industryAutocomplete"
                  outlined
                  flat
                  required
                  append-icon="mdi-chevron-down"
                  placeholder="Industry*"
                  class="input-field"
                />
              </v-col>
              <v-col cols="12" md="12" align="center">
                <v-btn
                  type="button"
                  class="btn-primary btn-submit-form"
                  :loading="loading"
                  @click="validate"
                  >Start free demo</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-overlay>
    <!-- End Dialog Pop Up -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      showNameIcon: false,
      showEmailIcon: false,
      showCompanyIcon: false,
      showNumberIcon: false,

      loading: false,
      isSubmitted: false,

      valid: true,
      firstname: "",
      lastname: "",
      nameRules: [(v) => !!v || "*Please fill your name"],
      company: "",
      companyRules: [(v) => !!v || "*Please fill your company"],
      email: "",
      emailRules: [
        (v) => !!v || "*Please fill your email",
        (v) => /.+@.+/.test(v) || "*Email must be valid",
      ],
      jobTitles: [
        "Marketing / PR Manager",
        "IT Manager",
        "Operation Manager",
        "General Manager",
        "Others",
      ],
      jobTitle: 0,
      employees: [
        "1-15 Employee",
        "16-100 Employee",
        "101-500 Employee",
        "501-1000 Employee",
        "1001+ Employee",
      ],
      employee: 0,
      mobileNumber: "",
      mobileRules: [
        (v) => !!v || "*Please fill your phone number",
        (v) => /^\d+$/.test(v) || "*Must be a number",
        (v) => v.length > 8 || "*Number not valid",
      ],
      industry: 0,
      industries: [
        "Fintech",
        "Education",
        "E-Commerce",
        "IT",
        "Travel",
        "Entertainment",
        "Retail",
        "Property",
        "Media",
        "Others",
      ],
      isValid: true,
      formatEmail: /.+@.+/,
      isEmail: true,
      formatNumber: /^\d+$/,
      isNumber: true,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  props: {
    dialog: Boolean,
    img: String,
    page: String,
  },

  methods: {
    validate() {
      // this.$refs.form.validate();
      this.isValid = this.$refs.form.validate();
      if (this.isValid == true) {
        // this.$refs.form.$el.submit();
        this.postDemoData();
        if (this.windowSize.x > 600) {
          this.$root.$emit("dialog2");
        } else {
          this.$root.$emit("bottomDialog");
        }
        this.returnData();
      } else if (this.isValid == false) {
        this.showErrorEmail(this.email);
        this.showErrorName(this.firstname);
        this.showErrorCompany(this.company);
        this.showErrorNumber(this.mobileNumber);
      }
    },

    returnData() {
      this.$emit("dialog", false);
      this.dialog = false;
    },

    showErrorEmail(val) {
      this.isEmail = this.formatEmail.test(val);
      if (val == "") {
        this.showEmailIcon = true;
      } else if (!this.isEmail) {
        this.showEmailIcon = true;
      } else {
        this.showEmailIcon = false;
      }
    },

    showErrorName(val) {
      if (val == "") {
        this.showNameIcon = true;
      } else {
        this.showNameIcon = false;
      }
    },

    showErrorCompany(val) {
      if (val == "") {
        this.showCompanyIcon = true;
      } else {
        this.showCompanyIcon = false;
      }
    },

    showErrorNumber(val) {
      this.isNumber = this.formatNumber.test(val);
      if (val == "") {
        this.showNumberIcon = true;
      } else if (!this.isNumber) {
        this.showNumberIcon = true;
      } else if (val.length < 8) {
        this.showNumberIcon = true;
      } else {
        this.showNumberIcon = false;
      }
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    jobOnFocus() {
      this.$refs.jobAutocomplete.isMenuActive = true;
    },

    employeeOnFocus() {
      this.$refs.employeeAutocomplete.isMenuActive = true;
    },

    industryOnFocus() {
      this.$refs.industryAutocomplete.isMenuActive = true;
    },

    async postDemoData() {
      const url = process.env.VUE_APP_SERVE_URL + "/api";
      const endpoint = "/request-demo";
      const data = {
        product_code: this.page.toUpperCase(),
        first_name: this.firstname,
        last_name: this.lastname,
        email: this.email,
        company_name: this.company,
        phone: this.mobileNumber,
        job_title: this.jobTitle,
        employee: this.employee,
        industry: this.industry,
      };
      const auth = "Bearer ".concat(
        `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZWE5M2MwZjhkYzBlMDgzYTY4MjM3YzMxY2Q0NWJkMWE3NTU4ZjI4YjNkZjkwMjUxNTdlMGNjNTRlM2ZkY2JjMDIxNDM0YTI2MzhhODg5NWEiLCJpYXQiOjE2Mjg5MzQ5MDcuNjkzNDUsIm5iZiI6MTYyODkzNDkwNy42OTM0NTQsImV4cCI6MTY2MDQ3MDkwNy42MjY3MTcsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.S22jBaZlKeqNGGumsfAaZZxmzAifU142lsgbGe8ap2wfVFG1_tqPkympy0iudoacspJFsdL86dj93usEI8ihakxyPUEB_077aJrXZVgxUIJP7H1FSSr4tOJnb9UVhJ4twptywhQ15tTp1B8OLSl775vqdZjDVR3DIn7SR7DByWSslmEngVAFFQxU91xymRDGLgcqye6dwkYd6VktViBjWWw7CQyqT0RdwrC9K2gm1IZqiRavX1U0uPCz6vqyQPTlQAVu8ybu__l_6NfIUjBC2gESdbqnYgwW0vqLGD-wc6HGpNOiDkv_Kgwj0xNXtqBPN25ojSfmxUMOvIlbtL9K05Yibx-etEA-zDHHuBwHFbRvUYxJWW01Pn6ZIMlTe8eKUSiMEMCQGiHxbukHFASwP92TfOR1-gut3Ix7OooBW_UODRg5pEDi6HnQJvl_Q5UmHxyhbqi9iNdx5wkNwUpCiJjHFl8yOD1EKyYPt1dJehBw6Cx2OusA1OmN8uuaoA2Jp2LLyATfn4YcbRKSN9CbNEH2zWnu0EKPFsWILfW2n_PqlZNMDLIZtP_e-uCyztkdAABLdPid96fDhx41VmoycscCs_u5BsTPLzI6LkZLNLjyx2o6MRGRyW2qLwxWYasrKT2dqB5at2ZAMoTG38QQdmWhV65XBZcJ_f5Y82zTmYA`
      );
      this.setLoading;
      await axios
        .post(url + endpoint, data, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          console.log(response.data.status.code);
          this.setLoading;
        });
    },

    setLoading() {
      this.loading = !this.loading;
    },
  },

  mounted() {
    this.onResize();

    let jobAutoCompleteInput = this.$refs.jobAutocomplete.$refs.input;
    jobAutoCompleteInput.addEventListener("focus", this.jobOnFocus, true);

    let employeeAutoCompleteInput = this.$refs.employeeAutocomplete.$refs.input;
    employeeAutoCompleteInput.addEventListener(
      "focus",
      this.employeeOnFocus,
      true
    );

    if (this.page === "Donya") {
      let industryAutoCompleteInput = this.$refs.industryAutocomplete.$refs
        .input;
      industryAutoCompleteInput.addEventListener(
        "focus",
        this.industryOnFocus,
        true
      );
    }
  },
};
</script>

<style scoped>
.dialog-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  color: #000000;
}

.v-text-field {
  font-family: Montserrat !important;
  border-radius: 8px;
}

.v-input--is-focused .v-input__slot {
  border: 1px solid #5c9ceb !important;
}

.input-long {
  width: 428px;
}

.form-column {
  padding: 0px 4px 0px 4px !important;
}

.btn-submit-form {
  width: 292px !important;
  height: 54px !important;
  font-family: Montserrat !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  border-radius: 32px !important;
}

div >>> .v-input__slot {
  padding: 0px 16px !important;
  margin-bottom: 4px !important;
}

div >>> .v-text-field__details {
  padding: 0px 4px !important;
  margin-bottom: 4px !important;
}

.radius-top {
  border-radius: 20px 20px 0 0;
}

.top {
  z-index: 1000 !important;
}

.icon-product {
  height: 65px;
  width: 65px;
}

div >>> .v-dialog {
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
  .dialog-title {
    font-size: 20px;
    line-height: 20px;

    color: #000000;
  }
}

@media only screen and (max-width: 360px) {
  .dialog-title {
    font-size: 18px;
    line-height: 20px;

    color: #000000;
  }

  .icon-product {
    height: 52px;
    width: 52px;
  }
}
</style>
